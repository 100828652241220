import React, { useState } from "react"
import ComparisonTab from "./ComparisonTab"
import SignatureTab from "./SignatureTab"

const App = () => {
  const [activeTab, setActiveTab] = useState("compare")

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1>RAY PDF Tool</h1>
      <div>
        <button
          onClick={() => setActiveTab("compare")}
          style={{ marginRight: "10px" }}
        >
          Сравнение Word
        </button>
        <button onClick={() => setActiveTab("signature")}>Подпись PDF</button>
      </div>

      {activeTab === "compare" && <ComparisonTab />}
      {activeTab === "signature" && <SignatureTab />}
    </div>
  )
}

export default App
