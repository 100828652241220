import React, { useState, useEffect, useRef } from "react"
import { useDropzone } from "react-dropzone"
import * as pdfjsLib from "pdfjs-dist/webpack"

const SignatureTab = () => {
  const [pdfFile, setPdfFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const [pdfFileName, setPdfFileName] = useState("")
  const [imageFileName, setImageFileName] = useState("")
  const [pdfDoc, setPdfDoc] = useState(null)
  const [pageNum, setPageNum] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [points, setPoints] = useState({})
  const canvasRef = useRef(null)
  const renderTaskRef = useRef(null)

  const onDropPdf = (acceptedFiles) => {
    setPdfFile(acceptedFiles[0])
    setPdfFileName(acceptedFiles[0].name)
    setPageNum(1)
    setPoints({})
  }

  const onDropImage = (acceptedFiles) => {
    setImageFile(acceptedFiles[0])
    setImageFileName(acceptedFiles[0].name)
  }

  const { getRootProps: getRootPropsPdf, getInputProps: getInputPropsPdf } =
    useDropzone({ onDrop: onDropPdf, accept: "application/pdf" })
  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } =
    useDropzone({ onDrop: onDropImage, accept: "image/*" })

  useEffect(() => {
    const loadPdf = async () => {
      if (pdfFile) {
        const fileReader = new FileReader()
        fileReader.onload = async () => {
          const typedArray = new Uint8Array(fileReader.result)
          const pdf = await pdfjsLib.getDocument(typedArray).promise
          setPdfDoc(pdf)
          setTotalPages(pdf.numPages)
          await renderPage(1, pdf)
        }
        fileReader.readAsArrayBuffer(pdfFile)
      }
    }

    loadPdf()
  }, [pdfFile])

  const renderPage = async (num, pdf) => {
    const canvas = canvasRef.current
    const context = canvas.getContext("2d")

    context.clearRect(0, 0, canvas.width, canvas.height)

    try {
      const page = await pdf.getPage(num)
      const viewport = page.getViewport({ scale: 1 })
      canvas.height = viewport.height
      canvas.width = viewport.width

      if (renderTaskRef.current) {
        renderTaskRef.current.cancel()
      }

      renderTaskRef.current = page.render({
        canvasContext: context,
        viewport: viewport,
      })
      await renderTaskRef.current.promise

      renderPoints(context, num)
    } catch (error) {
      console.error("Rendering error:", error)
    }
  }

  const renderPoints = (context, num) => {
    if (points[num]) {
      points[num].forEach((point) => {
        context.fillStyle = "red"
        context.fillRect(point.x - 1.5, point.y - 1.5, 3, 3)
      })
    }
  }

  const handlePdfClick = (event) => {
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    setPoints((prevPoints) => {
      const newPoints = { ...prevPoints }
      if (!newPoints[pageNum]) {
        newPoints[pageNum] = []
      }
      newPoints[pageNum].push({ x, y })
      return newPoints
    })

    renderPoints(canvas.getContext("2d"), pageNum)
  }

  const handleSubmit = () => {
    const formData = new FormData()
    formData.append("pdf_file", pdfFile)
    formData.append("image_file", imageFile)
    formData.append("points", JSON.stringify(points))

    fetch("http://gonzo-web.ru/api/sign-pdf", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error("Ошибка при подписи PDF")
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = "signed_document.pdf"
        document.body.appendChild(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const goToNextPage = () => {
    if (pageNum < totalPages) {
      setPageNum(pageNum + 1)
    }
  }

  const goToPrevPage = () => {
    if (pageNum > 1) {
      setPageNum(pageNum - 1)
    }
  }

  useEffect(() => {
    if (pdfDoc) {
      renderPage(pageNum, pdfDoc)
    }
  }, [pageNum, pdfDoc, points])

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h2>Подпись PDF</h2>
      <div>
        <h3>Выберите PDF файл</h3>
        <div
          {...getRootPropsPdf()}
          style={{
            border: "2px dashed #ccc",
            padding: "20px",
            cursor: "pointer",
            marginBottom: "20px",
          }}
        >
          <input {...getInputPropsPdf()} />
          <p>Перетащите PDF файл сюда или нажмите для выбора</p>
        </div>
        {pdfFileName && <p>Выбран файл: {pdfFileName}</p>}
      </div>
      <div>
        <h3>Выберите изображение</h3>
        <div
          {...getRootPropsImage()}
          style={{
            border: "2px dashed #ccc",
            padding: "20px",
            cursor: "pointer",
            marginBottom: "20px",
          }}
        >
          <input {...getInputPropsImage()} />
          <p>Перетащите изображение сюда или нажмите для выбора</p>
        </div>
        {imageFileName && <p>Выбран файл: {imageFileName}</p>}
      </div>
      {pdfFile && (
        <div>
          <h3>Предпросмотр PDF</h3>
          <canvas
            ref={canvasRef}
            onClick={handlePdfClick}
            style={{ cursor: "pointer" }}
          />
          <div>
            <button onClick={goToPrevPage} disabled={pageNum <= 1}>
              Предыдущая страница
            </button>
            <button onClick={goToNextPage} disabled={pageNum >= totalPages}>
              Следующая страница
            </button>
          </div>
          <p>
            Страница {pageNum} из {totalPages}
          </p>
          <button
            onClick={handleSubmit}
            disabled={Object.keys(points).length === 0}
          >
            Подписать
          </button>
        </div>
      )}
    </div>
  )
}

export default SignatureTab
