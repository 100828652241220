import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";

const ComparisonTab = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [resultText, setResultText] = useState("");
  const [file1Name, setFile1Name] = useState("");
  const [file2Name, setFile2Name] = useState("");
  const [fileOptions, setFileOptions] = useState([]);
  const [selectedFile1Id, setSelectedFile1Id] = useState("");

  // Получаем файлы из API
  const fetchFiles = async () => {
    try {
      const response = await axios.get(
        "https://coda.io/apis/v1/docs/ZsYgcR4slv/tables/grid-wAvwBrFWPR/rows?useColumnNames=true",
        {
          headers: {
            Authorization: `Bearer 0795ece2-0867-4436-a2a7-f2399a35c5b7`, // Замените TOKEN на ваш токен
          },
        }
      );
      setFileOptions(response.data.items);
    } catch (error) {
      console.error("Ошибка получения файлов:", error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const onDrop1 = (acceptedFiles) => {
    setFile1(acceptedFiles[0]);
    setFile1Name(acceptedFiles[0].name);
    setSelectedFile1Id(""); // Сбрасываем выбранный файл из селекта
  };

  const onDrop2 = (acceptedFiles) => {
    setFile2(acceptedFiles[0]);
    setFile2Name(acceptedFiles[0].name);
  };

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({ onDrop: onDrop1 });
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({ onDrop: onDrop2 });

  const handleCompare = async () => {
    console.log("Selected File ID:", selectedFile1Id);
    console.log("Uploaded File 1:", file1);

    // Проверяем, выбран ли файл либо через селект, либо через загрузку
    if (!selectedFile1Id && !file1) {
      alert("Пожалуйста, выберите исходный файл."); // Если нигде не выбрано, показываем предупреждение
      return;
    }
    if (!file2) {
      alert("Пожалуйста, выберите форматированный файл."); // Проверяем файл для сравнения
      return;
    }

    const formData = new FormData();

    if (file1) {
      formData.append("file1", file1); // если загружен файл, добавляем его
    }

    if (selectedFile1Id) {
      formData.append("file1_id", selectedFile1Id); // добавляем id выбранного файла как file1_id
    }

    formData.append("file2", file2); // добавляем форматированный файл

    const url = selectedFile1Id 
      ? "http://gonzo-web.ru/api/compare-docx-from-list" // если выбран id файла 
      : "http://gonzo-web.ru/api/compare-docx"; // если файл загружен

    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { result } = response.data;
      setResultText(result);
    } catch (error) {
      console.error("Ошибка при сравнении файлов:", error);
      if (error.response) {
        console.error("Ответ сервера с ошибкой:", error.response.data);
      }
    }
  };

  const renderHighlightedText = () => {
    const parts = [];
    let currentIndex = 0;

    while (currentIndex < resultText.length) {
      const addStartIndex = resultText.indexOf("{+", currentIndex);
      const removeStartIndex = resultText.indexOf("{-", currentIndex);
      const nextIndex = Math.min(
        addStartIndex === -1 ? Infinity : addStartIndex,
        removeStartIndex === -1 ? Infinity : removeStartIndex
      );

      if (nextIndex === Infinity) {
        parts.push(
          <span key={parts.length}>{resultText.slice(currentIndex)}</span>
        );
        break;
      }

      if (nextIndex > currentIndex) {
        parts.push(
          <span key={parts.length}>
            {resultText.slice(currentIndex, nextIndex)}
          </span>
        );
      }

      const isAdding = resultText[nextIndex + 1] === "+";
      const color = isAdding ? "green" : "red";
      const endIndex = resultText.indexOf(isAdding ? "+}" : "-}", nextIndex);
      if (endIndex !== -1) {
        parts.push(
          <span key={parts.length} style={{ color }}>
            {resultText.slice(nextIndex, endIndex + 2)}
          </span>
        );
        currentIndex = endIndex + 2;
      } else {
        break;
      }
    }

    return <>{parts}</>;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2>Сравнение Word</h2>
      <div>
        <h3>Выберите изначальный файл</h3>
        <div>
          <select
            onChange={(e) => {
              const selectedFile = fileOptions.find(item => item.id === e.target.value);
              if (selectedFile) { // Проверка на наличие выбранного файла
                setSelectedFile1Id(selectedFile.values.ID); // сохраняем ID файла из values
                setFile1Name(selectedFile.name); // показываем имя выбранного файла
                setFile1(null); // сбрасываем загруженный файл
                console.log("File selected from dropdown:", selectedFile.values.ID); // Отладка
              }
            }}
            value={selectedFile1Id}
          >
            <option value="">Выберите файл из списка</option>
            {fileOptions.map((file) => (
              <option key={file.id} value={file.id}>
                {file.name}
              </option>
            ))}
          </select>
        </div>
        <p>Выбранный файл: {file1Name}</p>
        
        {/* Dropzone для загрузки файла */}
        <div
          {...getRootProps1()}
          style={{
            border: "2px dashed #ccc",
            padding: "10px 5px 10px 5px",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps1()} />
          <p>Перетащите файл сюда или нажмите для выбора</p>
        </div>
        {file1Name && <p>Выбранный файл: {file1Name}</p>}
      </div>
      <div>
        <h3>Выберите форматированный файл</h3>
        <div
          {...getRootProps2()}
          style={{
            border: "2px dashed #ccc",
            padding: "10px 5px 10px 5px",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps2()} />
          <p>Перетащите файл сюда или нажмите для выбора</p>
        </div>
        {file2Name && <p>Выбранный файл: {file2Name}</p>}
      </div>
      <button
        onClick={handleCompare}
        style={{
          margin: "20px auto",
          padding: "10px 30px",
        }}
      >
        Сравнить
      </button>
      <div>
        <h3>Результат:</h3>
        <div>{renderHighlightedText()}</div>
      </div>
    </div>
  );
};

export default ComparisonTab;
